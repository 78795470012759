import React from "react";
import { Helmet } from "react-helmet";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import BenefitsSection from "./BenefitsSection";
import HeroSection from "./HeroSection";
import MetricsSection from "./MetricsSection";

const Partnership = () => (
  <>
    <Helmet>
      <title>Partner with Us - Advancing Healthcare Together</title>
      <meta
        name="description"
        content="Partner with Health In Her HUE to enhance healthcare equity. Collaborate with us to support women of color with culturally competent care."
      />
    </Helmet>
    <NavBar />
    <HeroSection />
    <BenefitsSection />
    <MetricsSection />
    <Footer />
  </>
);

export default Partnership;
