import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import NavBar from "components/NavBar";
import Footer from "components/Footer";
import CareSquadHeader from "./CareSquadHeader";
import CareSquadCards from "./CareSquadCards";

const Content = styled.div`
  background-color: #fbf6f4;
  height: 80px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  width: 100%;
`;

const CareSquads = () => (
  <>
    <Helmet>
      <title>Care Squads - Peer-to-Peer Support for Your Health Journey</title>
      <meta
        name="description"
        content="Join Care Squads for peer-to-peer support. Connect with dedicated teams focused on your health and well-being in a supportive community."
      />
    </Helmet>
    <Content>
      <NavBar />
    </Content>
    <CareSquadHeader />
    <CareSquadCards />
    <Footer />
  </>
);

export default CareSquads;
