import React from "react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import HIHHLogo from "assets/logos/app-bar-logo.svg";
import MobileNavMenu from "components/MobileNavMenu";
import { Container } from "components/styled";
import { useUserContext } from "components/UserContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import AvatarMenu from "routes/AccountSettings/AvatarMenu";
import { SESSION_ROUTES } from "routes/Session";
import colors from "styles/colors";
import MenuBase from "components/NavBar/MenuBase";
import {
  fireUserClickedLoginButtonEvent,
  fireUserClickedSignUpButtonEvent,
} from "utils/analytics";
import {
  NavLogo,
  NavbarButton,
  NavbarContainer,
  NavigationOptions,
} from "./NavBar.styles";
import {
  communityMenuItems,
  // connectionMenuItems,
  contentMenuItems,
} from "./NavBar.constants";

type NavBarProps = {
  isCommunityPage?: boolean;
};

const NavBar = ({ isCommunityPage }: NavBarProps) => {
  const { isAuthenticated: isLoggedIn, user } = useUserContext();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery("(max-width: 1200px)");
  const navigate = useNavigate();

  const navItemShouldBeActive = (url: string) => pathname.includes(url);

  const handleLoginClick = () => {
    const to = `/users${SESSION_ROUTES.SIGN_IN}${
      isCommunityPage ? "?community=true" : ""
    }`;
    fireUserClickedLoginButtonEvent(pathname === APP_ROUTES.carePoint);
    navigate(to, { replace: true, state: { from: pathname } });
  };

  const handleSignUpClick = () => {
    const to = `/users${SESSION_ROUTES.SIGN_UP}`;
    fireUserClickedSignUpButtonEvent(pathname === APP_ROUTES.carePoint);
    navigate(to, { replace: true, state: { from: pathname } });
  };

  return (
    <NavbarContainer
      style={{ marginTop: pathname === APP_ROUTES.home ? "64px" : "0" }}
    >
      <Container maxWidth="1200px" justifyContent="space-between">
        <a href={APP_ROUTES.home}>
          <NavLogo src={HIHHLogo} alt="app-bar-logo" />
        </a>
        {isMobile ? <MobileNavMenu /> : null}
        <NavigationOptions width="auto" gap="24px">
          <NavbarButton
            href={APP_ROUTES.directory}
            className={`navbar-item-btn ${
              navItemShouldBeActive(APP_ROUTES.directory) ? "active" : ""
            }`}
          >
            <Typography
              id="provider-directory-typography"
              variant="link1"
              fontSize={18}
              fontWeight={400}
              color={colors.NUDE[9]}
              sx={{ pr: "10px" }}
            >
              Provider Directory
            </Typography>
          </NavbarButton>
          <MenuBase
            items={contentMenuItems}
            label="Content"
            idtouse="content-menu-button"
          />
          <MenuBase
            items={communityMenuItems}
            label="Community"
            idtouse="community-menu-button"
          />
          {/* <MenuBase items={connectionMenuItems} label="Connection" /> */}
          <NavbarButton
            href={APP_ROUTES.partnership}
            className={`navbar-item-btn ${
              navItemShouldBeActive(APP_ROUTES.partnership) ? "active" : ""
            }`}
          >
            <Typography
              variant="link1"
              fontSize={18}
              fontWeight={400}
              color={colors.NUDE[9]}
            >
              Partners
            </Typography>
          </NavbarButton>
          {!isLoggedIn && (
            <Button
              name="session-button"
              variant="contained"
              onClick={handleSignUpClick}
            >
              Join
            </Button>
          )}
          {!isLoggedIn && (
            <Button
              name="session-button"
              variant="outlined"
              onClick={handleLoginClick}
            >
              Log In
            </Button>
          )}
          {isLoggedIn && user && <AvatarMenu />}
        </NavigationOptions>
      </Container>
    </NavbarContainer>
  );
};

export default NavBar;
