import api from "api";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { User } from "hooks/useUserInfo";
import React, { useContext, useEffect, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { APP_ROUTES } from "routes";
import { MEMBERSHIP_REDIRECTED_KEY } from "routes/Membership/Plans/PlanCard";
import { PROVIDER_ONBOARDING_STEPS } from "routes/Onboarding";

import { SESSION_ROUTES } from "routes/Session";
import {
  fireUserRegisteredEvent,
  fireUserSignedInEvent,
} from "utils/analytics";

import { addToast } from "./common/HIHHToast";

type State = {
  displayBirthdayReminder: boolean;
  isAuthenticated: boolean;
  isOnboardingCompleted: boolean;
  user: User | null;
  afterOnboardingRedirect: (user: User) => void;
  confirmUserEmail: (confirmationToken: string) => Promise<void>;
  logOut: () => Promise<void>;
  oauthSignIn: (payload: any) => Promise<void>;
  redirectUser: (user: any) => void;
  refreshUser: () => any;
  resetUserPassword: (
    passwordToken: string,
    password: string,
    passwordConfirmation: string
  ) => Promise<void>;
  setDisplayBirthdayReminder: (display: boolean) => void;
  signIn: (email: any, password: any) => Promise<void>;
  signOut: () => Promise<void>;
  signInAfterSignUp: (email: any, password: any) => Promise<void>;
};

const UserContext = React.createContext<State>({} as State);

const USER_KEY = "user";

/*-------------------------------------------------------------------------------------*/
  /* July 31, 2024: K.Suazo
  Removing member onboarding for currentOnboardingPath because of the removal
  of the member onboarding process */
/*-------------------------------------------------------------------------------------*/

export const currentOnboardingPath = (userData: User) => {
  const onboardingRoutes = PROVIDER_ONBOARDING_STEPS

  return `/${userData.role}/onboarding/${userData.sign_up_stage}/${
    onboardingRoutes[userData.sign_up_stage - 1].pathName
  }`;
};

type LocationState = {
  from: Location;
};

export const UserContextProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigationState = location.state as LocationState;

  const inSignInScreen =
    location.pathname === `/users${SESSION_ROUTES.SIGN_IN}`;
  const queryParams = new URLSearchParams(window.location.search);

  const lsUser = localStorage.getItem(USER_KEY);
  const [user, _setUser] = useState<User | null>(
    lsUser ? JSON.parse(lsUser) : null
  );

  const [displayBirthdayReminder, setDisplayBirthdayReminder] = useState(false);

  let isOnboardingCompleted = false;
  if (user) isOnboardingCompleted = user.onboarding_completed;

  const identifyUserAnalyticsTools = () => {
    if (user && (user.onboarding_started || user.onboarding_completed)) {
      const userData = {
        name: user.full_name,
        email: user.email,
      };
      window.analytics.identify(user.id, userData);
      if (window.__insp) {
        window.__insp.push(["identify", user.id]);
        window.__insp.push(["tagSession", userData]);
      }
    }
  };

/*-------------------------------------------------------------------------------------*/
  /* August 2, 2024: K.Suazo
  Commeting out the A/B Testing information for new Hubspot users
  because we stopped A/B Testing for the member onboarding process.
  I also commented it out in the hubspot_contact_creator service */
/*-------------------------------------------------------------------------------------*/

  const createHubspotUser = async (userPayload) => {
    try {
      // const geoLocationResponse = await axios.get("https://api.ipify.org/?format=json");
      // const ipAddress = geoLocationResponse.data.ip;
      // const abtestResponse = await api.get("/abtest_visitor_trackers", {
      //   params: { ip_address: ipAddress },
      // });

      await api.post("/hubspot", {
        user_id: userPayload.id,
        email: userPayload.email,
        first_name: userPayload.first_name,
        last_name: userPayload.last_name,
        sso_sign_up: userPayload.provider,
        user_role: userPayload.role,
        lifecyclestage: "180052808",
        onboarding_stage: userPayload.sign_up_stage,
        // uuid: abtestData.uuid,
        // ip_address: abtestData.ip_address,
        // first_time_visitor: abtestData.first_time_visitor,
        // visitor_test_group: abtestData.visitor_test_group,
        // visitor_country: abtestData.visitor_country,
        // visitor_state: abtestData.visitor_state,
        // visitor_city: abtestData.visitor_city,
        // device_info: abtestData.device_info,
        // created_at: abtestData.created_at,
        // updated_at: abtestData.updated_at,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error creating user in HubSpot:", error);
      throw error;
    }
  };

  const redirectLogoutUser = () => {
    localStorage.clear();
    _setUser(null);
    if (!inSignInScreen) {
      navigate(APP_ROUTES.signIn, { replace: true });
    }
  };

  const redirectAfterMembershipJoinAttempt = () => {
    localStorage.removeItem(MEMBERSHIP_REDIRECTED_KEY);
    // navigate to account settings, subscription tab
    window.location.replace(`${APP_ROUTES.accountSettings}?tabIndexValue=2`);
  };

  const onboardingCompletedRedirect = (userPayload) => {
    if (queryParams.get("community")) {
      navigate(APP_ROUTES.community, { replace: true });
      return;
    }

    if (queryParams.get("content") && queryParams.get("post_slug")) {
      // go to blog post
      window.location.replace(
        `${process.env.REACT_APP_CONTENT_URL}/${queryParams.get("post_slug")}`
      );
      return;
    }

    if (!navigationState && userPayload.role === "provider") {
      // go to provider profile
      navigate(`${APP_ROUTES.providerProfile}/${userPayload.id}`, {
        replace: true,
      });
      return;
    }
    // Might be moved because membership is not viewed after signing up
    if (
      userPayload.role === "member" &&
      localStorage.getItem(MEMBERSHIP_REDIRECTED_KEY) === "true"
    ) {
      redirectAfterMembershipJoinAttempt();
      return;
    }

    if (navigationState && Object.keys(navigationState).length > 0) {
      navigate(navigationState.from, { replace: true });
      return;
    }

    navigate("/account_settings");
  };

  const redirectUser = (userPayload) => {
    const inSignUpScreen = location.pathname === `/users${SESSION_ROUTES.SIGN_UP}`;

    if (inSignInScreen) {
      fireUserSignedInEvent(userPayload);
    }

    if (!userPayload.user_goals_interacted && inSignUpScreen) {
      if (userPayload.role === 'member'){
        navigate(`/users${SESSION_ROUTES.USER_GOALS}`,
          {
            state: { email: userPayload.email }
          }
        );
      } else {
        navigate(`${APP_ROUTES.providerProfile}/${userPayload.id}`, {
          replace: true,
        });
      }
      return;
    }
    
    onboardingCompletedRedirect(userPayload);
  };

  const setUser = ({ data, headers }) => {
    _setUser(data);

    localStorage.setItem(USER_KEY, JSON.stringify(data));
    localStorage.setItem("token", headers.authorization.replace("Bearer ", ""));
  };

  const signOut = async () => redirectLogoutUser();

  const refreshUser = async () => {
    if (user) {
      try {
        const { data, headers } = await api.get("/users/_me");
        setUser({ data, headers });
        return data;
      } catch (err) {
        addToast("Something went wrong. Please try again later.", "error");
        signOut();
      }
    }
    return null;
  };

  useEffect(() => {
    refreshUser();
  }, []);

  useEffect(() => {
    if (user) {
      identifyUserAnalyticsTools();
    }
  }, [user]);

  const signIn = async (email, password) => {
    const { data, headers } = await api.post("/users/sign_in", {
      user: { email, password },
    });

    setUser({ data, headers });
    redirectUser(data);
  };

  const signInAfterSignUp = async (email, password) => {
    const { data, headers } = await api.post("/users/sign_in", {
      user: { email, password },
    });

    setUser({ data, headers });

    if (data.role === 'member') {
      await api.put("/users", {user: {
        sign_up_stage: 5,
      },});
      data.sign_up_stage = 5;
    }

    createHubspotUser(data);

    redirectUser(data);
  };

  const oauthSignIn = async (payload) => {
    const { data, headers } = await api.post("/users/oauth/callback", {
      ...payload,
    });

    if (payload.from_sign_up) fireUserRegisteredEvent(payload);
    setUser({ data, headers });

    if (data.role === 'member') {
      await api.put("/users", {user: {
        sign_up_stage: 5,
      },});
      data.sign_up_stage = 5;
    }

    createHubspotUser(data);

    redirectUser(data);
  };

  const logOut = async () => {
    await api.delete("/users/sign_out");
    signOut();
  };

  const confirmUserEmail = async (confirmationToken) => {
    try {
      const { data, headers } = await api.get(
        `/users/confirmation?confirmation_token=${confirmationToken}`
      );
      setUser({ data, headers });
      redirectUser(data);
    } catch (error) {
      navigate(`/users${SESSION_ROUTES.SIGN_IN}`, { replace: true });
    }
  };

  const resetUserPassword = async (
    passwordToken,
    password,
    password_confirmation
  ) => {
    await api.put("/users/password", {
      user: {
        reset_password_token: passwordToken,
        password,
        password_confirmation,
      },
    });
  };

  const createVisitorTrackerAndSetCookie = async () => {
    try {
      const isCookieSet = document.cookie.includes("visitor_cookie=true");
      const res = await axios.get("https://ipinfo.io/json");
      const { ip, country, region, city } = res.data;

      let deviceType;
      if (isMobile) {
        deviceType = "Mobile";
      } else if (isTablet) {
        deviceType = "Tablet";
      } else {
        deviceType = "Desktop";
      }

      const referralSource = document.referrer;
      const randomNumber = Math.random();
      const visitorTestGroup = randomNumber < 0.5 ? "control" : "variant";

      let shouldPost = false;

      if (isCookieSet) {
        const existingIp = await api.get("/abtest_visitor_trackers", { params: { ip_address: ip } });
        if (existingIp.data === null) {
          shouldPost = true;
        }
      } else {
        shouldPost = true;
        const expires = new Date(
          Date.now() + 30 * 24 * 60 * 60 * 1000
        ).toUTCString();
        document.cookie = `visitor_cookie=true; expires=${expires}; SameSite=Lax`;
      }
      
      if (shouldPost) {
        await api.post("/abtest_visitor_trackers", {
          uuid: uuidv4(),
          ip_address: ip,
          visitor_test_group: visitorTestGroup,
          first_time_visitor: true,
          visitor_country: country,
          visitor_state: region,
          visitor_city: city,
          device_info: deviceType,
          referral_source: referralSource,
        });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error:", error);
    }
  };


  useEffect(() => {
    if (!user) {
      createVisitorTrackerAndSetCookie();
    }
  }, [user]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    displayBirthdayReminder,
    isAuthenticated: !!user,
    isOnboardingCompleted,
    user,
    afterOnboardingRedirect: onboardingCompletedRedirect,
    confirmUserEmail,
    logOut,
    oauthSignIn,
    refreshUser,
    redirectUser,
    resetUserPassword,
    setDisplayBirthdayReminder,
    signIn,
    signOut,
    signInAfterSignUp,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (typeof context === "undefined") {
    throw new Error("useSession must be used within a SessionContext");
  }
  return context;
};
