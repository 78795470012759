import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { Container, HIHHRoundLogo } from "components/styled";
import colors from "styles/colors";
import HIHHLogo from "assets/logos/brand/hihh-round-logo.svg";

export const PrivacyPolicyWrapper = styled(Container)`
  margin: 0 auto;
  max-width: 814px;
  line-height: 1.6;
  @media all and (max-width: 480px) {
    width: calc(100% - 36px);
  }
`;

export const OrderList = styled.ol`
  color: #222;
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.8em;
  margin: 0;
  padding: 0 0 0 18px;
  text-transform: none;
`;

export const Anchor = styled.a`
  color: ${colors.CORAL[5]};
  font-family: "Nunito Sans, sans-serif";
  font-size: "0.875rem";
  font-weight: 700;
  line-height: "20px";
  text-decoration: none;
`;

export const UnorderList = styled.li`
  margin-bottom: 9px;
  font-size: 17px;
  font-weight: 600;
`;

const PrivacyPolicy = () => (
  <PrivacyPolicyWrapper
    gap="24px"
    direction="column"
    justifyContent="space-between"
    alignItems="start"
    padding="18px"
  >
    <HIHHRoundLogo src={HIHHLogo} alt="logo" />
    <Typography variant="h3">Privacy Policy</Typography>

    <Typography variant="h5">
      <b>Last Updated: February 3, 2024</b>
    </Typography>

    <Typography variant="h6">
      Health in Her HUE (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;)
      operates the healthinherhue.com website (the &quot;Service&quot;).
    </Typography>

    <Typography variant="h6">
      This page informs you of our policies regarding the collection, use, and
      disclosure of personal data when you use our Service and the choices you
      have associated with that data.
    </Typography>

    <Typography variant="h6">
      By using the Service, you agree to the collection and use of information
      in accordance with this Privacy Policy.
    </Typography>

    <Typography variant="h5">
      <b>Information Collection And Use</b>
    </Typography>

    <Typography variant="h6">
      We collect several different types of information for various purposes to
      provide and improve our Service to you. While using our Service, we may
      ask you to provide us with certain personally identifiable information
      that can be used to contact or identify you (&quot;Personal Data&quot;).
      Personal Data may include, but is not limited to:
    </Typography>

    <ul>
      <UnorderList>Email address</UnorderList>
      <UnorderList>First name and last name</UnorderList>
      <UnorderList>
        Information about how you access and use the Service such as your
        computer’s Internet Protocol (IP) address browser type, browser version,
        the pages of our Service that you visit, the time and date of your
        visit, the time spent on those pages, unique device identifiers and
        other diagnostic data.
      </UnorderList>
    </ul>

    <Typography variant="h6">
      We may collect your Personal Data directly from you when you complete the
      sign-up form to create your user profile on Health In Her HUE. We also may
      collect Personal Data through the use of cookies and other tracking
      technologies.
    </Typography>

    <Typography variant="h6">
      In general, we use your Personal Data to provide and improve the Service.
      This may include:
    </Typography>

    <ul>
      <UnorderList>Notifying you about changes to our Service</UnorderList>
      <UnorderList>
        Allowing you to participate in interactive features of our Service when
        you choose to do so
      </UnorderList>
      <UnorderList>Providing customer care and support</UnorderList>
      <UnorderList>
        Obtaining analysis or valuable information so that we can improve the
        Service
      </UnorderList>
      <UnorderList>
        Monitoring the usage of the Service to personalize and improve the
        Service, analyze usage and trends, and otherwise measure the
        effectiveness of the service to develop new features
      </UnorderList>
      <UnorderList>
        Detecting, preventing and addressing technical issues
      </UnorderList>
      <UnorderList>
        Conducting market research to better understand the needs of our users
      </UnorderList>
      <UnorderList>Marketing</UnorderList>
      <UnorderList>Promotion of our events</UnorderList>
      <UnorderList>
        Display relevant advertisements from our partners
      </UnorderList>
    </ul>

    <Typography variant="h5">
      <b>Transfer of Data</b>
    </Typography>

    <Typography variant="h6">
      Your Personal Data may be transferred to — and maintained on — computers
      located outside of your state, province, country or other governmental
      jurisdiction where the data protection laws may differ than those from
      your jurisdiction. If you are located outside United States and choose to
      provide information to us, please note that we transfer Personal Data to
      United States and process it there.
    </Typography>

    <Typography variant="h5">
      <b>Disclosure of Data</b>
    </Typography>

    <Typography variant="h6">
      Health in Her HUE may disclose your Personal Data in certain circumstances
      when it is necessary to operate the Service, if you ask us to share your
      Personal Data with someone else, or if we have a legal reason to do so.
    </Typography>

    <Typography variant="h6">
      We may disclose your Personal Data to service providers who we have
      engaged to provide the Service on our behalf, to perform Service-related
      services or to assist us in analyzing how our Service is used. We take
      steps to prevent service providers from using your Personal Data outside
      of our business relationship with them.
    </Typography>

    <Typography variant="h6">
      We may also disclose your Personal Data in the context of a business
      transition, such as a merger, acquisition, divestiture, restructuring,
      reorganization, dissolution, bankruptcy or sale of all or a portion of our
      assets, we may disclose your personal information to the party or parties
      of such transaction.
    </Typography>

    <Typography variant="h6">
      Finally, we may disclose your Personal Data if we have the good faith
      belief that such action is necessary to:
    </Typography>

    <ul>
      <UnorderList>Comply with a legal obligation</UnorderList>
      <UnorderList>
        Protect and defend the rights or property of Health in Her HUE
      </UnorderList>
      <UnorderList>
        Prevent or investigate possible wrongdoing in connection with the
        Service
      </UnorderList>
      <UnorderList>
        Protect the personal safety of users of the Service or the public
      </UnorderList>
      <UnorderList>Protect against legal liability</UnorderList>
    </ul>

    <Typography variant="h5">
      <b>Tracking & Cookies Data</b>
    </Typography>

    <Typography variant="h6">
      We use cookies and similar tracking technologies to track the activity on
      our Service and hold certain information.
    </Typography>

    <Typography variant="h6">
      Cookies are files with a small amount of data which may include an
      anonymous unique identifier. Cookies are sent to your browser from a
      website and stored on your device. Examples of Cookies we use include:
    </Typography>

    <ul>
      <UnorderList>
        Session Cookies. We use Session Cookies to operate our Service.
      </UnorderList>
      <UnorderList>
        Preference Cookies. We use Preference Cookies to remember your
        preferences and various settings.
      </UnorderList>
      <UnorderList>
        Security Cookies. We use Security Cookies for security purposes.
      </UnorderList>
    </ul>

    <Typography variant="h6">
      Tracking technologies also used are beacons, tags, and scripts to collect
      and track information and to improve and analyze our Service. For example,
      we use
    </Typography>

    <Typography variant="h6">
      Google Analytics, which is a web analytics service offered by Google that
      tracks and reports website traffic. Google uses the data collected to
      track and monitor the use of our Service. This data is shared with other
      Google services. Google may use the collected data to contextualize and
      personalize the ads of its own advertising network.
    </Typography>

    <Typography variant="h6">
      You can opt-out of having made your activity on the Service available to
      Google Analytics by installing the Google Analytics opt-out browser
      add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
      analytics.js, and dc.js) from sharing information with Google Analytics
      about visits activity. For more information on the privacy practices of
      Google, please visit the{" "}
      <Anchor href="https://policies.google.com/privAnchorcy">
        Google Privacy & Terms
      </Anchor>{" "}
      web page.
    </Typography>

    <Typography variant="h6">
      You can instruct your browser to refuse all cookies or to indicate when a
      cookie is being sent. However, if you do not accept cookies, you may not
      be able to use some portions of our Service.
    </Typography>

    <Typography variant="h6">
      Some web browsers incorporate a &quot;Do Not Track&quot; feature
      (&quot;DNT&quot;) that signals to the websites that you visit that you do
      not want to have your activity tracked. Our Service does not currently
      respond to your browser’s DNT signals.
    </Typography>

    <Typography variant="h5">
      <b>Security of Data</b>
    </Typography>

    <Typography variant="h6">
      The security of your Personal Data is important to us, but remember that
      no method of transmission over the Internet, or method of electronic
      storage is 100% secure. While we strive to use commercially acceptable
      means to protect your personal data, we cannot guarantee its absolute
      security.
    </Typography>

    <Typography variant="h5">
      <b>Google Maps API</b>
    </Typography>

    <Typography variant="h6">
      We leverage the Google Maps API to provide services to you. The Google
      Maps API is a collection of services that allow us to include maps,
      geocoding, places, and other content from Google in our web pages or
      applications.
    </Typography>

    <Typography variant="h5">
      <b>Links to Other Sites</b>
    </Typography>

    <Typography variant="h6">
      Our Service may contain links to other sites that are not operated by us.
      If you click on a third party link, you will be directed to that third
      party’s site. We strongly advise you to review the privacy policy of every
      site you visit. We have no control over and assume no responsibility for
      the content, privacy policies or practices of any third party sites or
      services.
    </Typography>

    <Typography variant="h5">
      <b>Children’s Privacy</b>
    </Typography>

    <Typography variant="h6">
      Our Service does not address anyone under the age of 18
      (&quot;Children&quot;). We do not knowingly collect Personal Data from
      anyone under the age of 18. If you are a parent or guardian and you are
      aware that your Children has provided us with Personal Data, please
      contact us. If we become aware that we have collected Personal Data from
      children without verification of parental consent, we take steps to remove
      that information from our servers.
    </Typography>

    <Typography variant="h5">
      <b>Changes to This Privacy Policy</b>
    </Typography>

    <Typography variant="h6">
      We may update our Privacy Policy from time to time. we may let you know of
      these updates via email (if we have your email address on file) and / or a
      prominent notice on our Service. We will also update the &quot;effective
      date&quot; at the top of this Privacy Policy.
    </Typography>

    <Typography variant="h6">
      You are advised to review this Privacy Policy periodically for any
      changes. Changes to this Privacy Policy are effective when they are posted
      on this page.
    </Typography>

    <Typography variant="h5">
      <b>Contact Us</b>
    </Typography>

    <Typography variant="h6">
      If you have any questions about this Privacy Policy, please contact us:{" "}
      <Anchor href="mailto:support@healthinherhue.com">
        support@healthinherhue.com
      </Anchor>
      . You may also contact us if you wish you correct any Personal Data that
      we maintain about you.
    </Typography>
  </PrivacyPolicyWrapper>
);

export default PrivacyPolicy;
