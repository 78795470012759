import { ThemeProvider } from "@mui/material";
import BirthdateReminderModal from "components/BirthdateReminderModal";
import HIHHToast from "components/common/HIHHToast";
import PageLoader from "components/common/PageLoader";
import { UserContextProvider } from "components/UserContextProvider";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Routes from "routes";
import { QueryParamProvider } from "use-query-params";

import theme from "./styles/theme";

declare global {
  interface Window {
    analytics: any;
    __insp: any;
  }
}

const RouteAdapter = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      // eslint-disable-next-line no-shadow
      replace(location) {
        navigate(location, { replace: true, state: location.state });
      },
      // eslint-disable-next-line no-shadow
      push(location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );

  return children({ history: adaptedHistory, location });
};

const App = () => (
  <Router>
    <HIHHToast />
    <UserContextProvider>
      <ThemeProvider theme={theme}>
        <QueryParamProvider ReactRouterRoute={RouteAdapter}>
          <Suspense fallback={<PageLoader />}>
            <Helmet>
              <title>Health In Her HUE - Find Culturally Competent Care</title>
              <meta
                name="description"
                content="Find and connect with healthcare providers who understand the unique needs of women of color. Explore resources tailored to your health journey."
              />
            </Helmet>
            <BirthdateReminderModal />
            <Routes />
          </Suspense>
        </QueryParamProvider>
      </ThemeProvider>
    </UserContextProvider>
  </Router>
);

export default App;
